import React from 'react';
import { Link, graphql } from 'gatsby';
import { colors, dimensions, breakpoints, transitions } from '../styles/variables';
import Section from './Section';
import orderPages from '../utils/orderPages';

export const AsideNavigationMenuItemFragment = graphql`
  fragment AsideNavigationMenuItemFragment on StrapiQuery_Page {
    id
    title
    path
    parent_order
    children {
      id
      title
      path
      parent_order
    }
  }
`;

export type AsideNavigationMenuItem = {
  id: string;
  title: string;
  path: string;
  parent_order?: number;
  children: {
    id: string;
    title: string;
    path: string;
    parent_order?: number;
  }[];
};

const AsideNavigation: React.FC<{ menuItems: AsideNavigationMenuItem[]; currentPath: string }> = ({
  menuItems,
  currentPath,
}) => {
  return (
    <Section level={3} css={{ position: 'relative' }}>
      <div
        css={{
          position: 'absolute',
          width: 300,
          height: 0,
          right: 0,
          top: -8,
          [`@media (max-width: 1200px)`]: {
            width: 250,
          },
          [`@media (max-width: 874px)`]: {
            width: 'auto',
            height: 'auto',
            position: 'relative',
            maxWidth: 570,
          },
          [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'none',
          },
        }}
      >
        {orderPages(menuItems).map(({ id, title, path, children }, index) => {
          const active = currentPath === path || currentPath.startsWith(`${path}/`);
          const showChildren = active && children && children.length > 0;

          return (
            <div
              key={id}
              css={{
                borderTop: index > 0 && showChildren ? `1px solid ${colors.turquoise}` : 'none',
                borderBottom: showChildren ? `1px solid ${colors.turquoise}` : 'none',
                padding: showChildren ? '1rem 0' : '0.5rem 0',
                margin: showChildren ? '0.5rem 0' : 0,
              }}
            >
              <Link
                to={path}
                css={{
                  textDecoration: active ? 'none' : 'underline',
                  color: active ? colors.black : colors.blue,
                  transition: transitions.default,
                  '&:hover, &:focus': {
                    textDecoration: active ? 'none' : 'underline',
                    color: active ? colors.black : colors.purple,
                  },
                }}
              >
                {title}
              </Link>
              {children && showChildren && (
                <div css={{ padding: '1rem 0 0 1rem' }}>
                  {orderPages(children).map(child => {
                    const childActive = currentPath === child.path;

                    return (
                      <div key={child.id}>
                        <Link
                          to={child.path}
                          css={{
                            fontSize: `${dimensions.fontSize.small}rem`,
                            color: childActive ? colors.black : colors.blue,
                            textDecoration: childActive ? 'none' : 'underline',
                            transition: transitions.default,
                            '&:hover, &:focus': {
                              textDecoration: active ? 'none' : 'underline',
                              color: childActive ? colors.black : colors.purple,
                            },
                          }}
                        >
                          {child.title}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default AsideNavigation;
