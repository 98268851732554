import React from 'react';
import { Link, graphql } from 'gatsby';
import { ImageFragment } from '../fragments/Image';
import { Article } from './Frontpage/NewsSection';
import Section from './Section';
import { breakpoints, fonts, dimensions, colors, transitions } from '../styles/variables';
import ArrowDownIcon from '../icons/arrow-down.svg';

export const PostListItemFragment = graphql`
  fragment PostListItemFragment on StrapiQuery_Post {
    id
    path
    title
    description
    image {
      ...ImageFragment
    }
  }
`;

export type PostListItem = {
  id: string;
  path: string;
  title: string;
  description: string;
  image?: ImageFragment;
};

const Pagination: React.FC<{ to: string; isCurrent?: boolean }> = ({ children, to, isCurrent }) => (
  <Link
    to={to}
    css={{
      width: 35,
      height: 35,
      margin: '0 2px',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.serif,
      paddingBottom: 2,
      fontWeight: isCurrent ? dimensions.fontWeight.bold : dimensions.fontWeight.regular,
      textDecoration: 'none',
      backgroundColor: isCurrent ? colors.yellow : 'none',
      transition: transitions.fast,
      '&:hover, &:focus': {
        backgroundColor: colors.yellow,
        textDecoration: 'none',
      },
    }}
  >
    {children}
  </Link>
);

const PostList: React.FC<{
  posts: PostListItem[];
  numberOfPages: number;
  currentPage: number;
  blogPath: string;
  className?: string;
}> = ({ posts, numberOfPages, currentPage, blogPath, className }) => {
  return (
    <>
      <Section level={3}>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            marginLeft: -25,
            marginRight: -25,
            [`@media (max-width: ${breakpoints.md}px)`]: {
              display: 'block',
              marginLeft: 0,
              marginRight: 0,
            },
          }}
          className={className}
        >
          {posts.map(post => (
            <Article
              key={post.id}
              article={post}
              css={{
                width: '33%',
                paddingLeft: 25,
                paddingRight: 25,
                marginBottom: 40,
                [`@media (max-width: ${breakpoints.lg}px)`]: {
                  width: '50%',
                },
                [`@media (max-width: ${breakpoints.md}px)`]: {
                  width: '100%',
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
            />
          ))}
        </div>
      </Section>
      <Section level={4}>
        <div css={{ padding: 20, display: 'flex' }}>
          {currentPage > 1 && (
            <Pagination to={currentPage === 2 ? blogPath : `${blogPath}/${currentPage - 1}`}>
              <ArrowDownIcon
                css={{ transform: 'translate3d(-1px, 1px, 0) rotate(90deg) scale(0.8)' }}
              />
            </Pagination>
          )}
          {Array.from({ length: numberOfPages }).map((_, i) => {
            return (
              <Pagination
                key={i} // eslint-disable-line react/no-array-index-key
                to={i === 0 ? blogPath : `${blogPath}/${i + 1}`}
                isCurrent={i + 1 === currentPage}
              >
                {i + 1}
              </Pagination>
            );
          })}
          {currentPage < numberOfPages && (
            <Pagination to={`${blogPath}/${currentPage + 1}`}>
              <ArrowDownIcon
                css={{ transform: 'translate3d(1px, 1px, 0) rotate(-90deg) scale(0.8)' }}
              />
            </Pagination>
          )}
        </div>
      </Section>
    </>
  );
};

export default PostList;
