import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { ImageFragment } from '../fragments/Image';
import TitleLargeImage from '../components/TitleLargeImage';
import TitleSmallImage from '../components/TitleSmallImage';
import AsideNavigation, { AsideNavigationMenuItem } from '../components/AsideNavigation';
import DynamicComponent, { DynamicComponentFragment } from '../components/DynamicComponent';
import PostList, { PostListItem } from '../components/PostList';
import { PageChildFragment } from '../components/DynamicComponent/PageChildren';
import PageParallax from '../components/PageParallax';

export const query = graphql`
  query PageTemplateQuery($id: ID!, $limit: Int!, $skip: Int!) {
    strapi {
      navigation {
        id
        main {
          id
          page {
            id
            path
            children {
              ...AsideNavigationMenuItemFragment
            }
          }
        }
        blog_page {
          id
          path
        }
      }
      page(id: $id) {
        id
        path
        title
        description
        large_top_image
        show_sibling_and_child_links
        image {
          ...ImageFragment
        }
        content {
          ...DynamicComponentFragment
        }
        children {
          ...PageChildrenFragment
        }
      }
      posts(limit: $limit, start: $skip, sort: "created_at:desc") {
        ...PostListItemFragment
      }
    }
  }
`;

export type Page = {
  id: string;
  path: string;
  title: string;
  description: string;
  large_top_image: boolean;
  show_sibling_and_child_links: boolean;
  image?: ImageFragment;
  content: DynamicComponentFragment[];
  children: PageChildFragment[];
};

export type PageTemplateQuery = {
  strapi: {
    page: Page;
    navigation: {
      id: string;
      main: {
        page: {
          id: string;
          path: string;
          children: AsideNavigationMenuItem[];
        };
      }[];
      blog_page: {
        id: string;
        path: string;
      };
    };
    posts: PostListItem[];
  };
};

interface PageTemplateProps {
  data: PageTemplateQuery;
  pathContext: {
    numberOfPages: number;
    currentPage: number;
    showPosts: boolean;
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    strapi: { page, navigation, posts },
  },
  pathContext: { numberOfPages, currentPage, showPosts },
}) => {
  const currentMainNav = navigation.main.find(n => page.path.startsWith(n.page.path));
  const titleProps = {
    title: page.title,
    description: page.description,
    image: page.image,
  };

  return (
    <>
      <Helmet title={page.title} meta={[{ name: 'description', content: page.description }]} />
      {page.large_top_image ? (
        <TitleLargeImage {...titleProps} />
      ) : (
        <TitleSmallImage {...titleProps} />
      )}
      <div css={{ position: 'relative' }}>
        <PageParallax seed={Number(page.id)} />
        <div css={{ position: 'relative', zIndex: 2 }}>
          {page.show_sibling_and_child_links && currentMainNav && currentMainNav.page.children && (
            <AsideNavigation menuItems={currentMainNav.page.children} currentPath={page.path} />
          )}
          {page.content.map((props, index) => (
            <div
              key={index} // eslint-disable-line react/no-array-index-key
              css={{ marginTop: '0.6rem' }}
            >
              <DynamicComponent dynamicProps={props} pageChildren={page.children} index={index} />
            </div>
          ))}
          {showPosts && (
            <PostList
              posts={posts}
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              blogPath={page.path}
              css={{ marginTop: 140 }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PageTemplate;
