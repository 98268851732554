import React from 'react';
import { colors, dimensions, breakpoints } from '../styles/variables';
import Parallax, { Triangle, Ring } from './Parallax';
import Section from './Section';
import { ImageFragment } from '../fragments/Image';
import sanitizeSrcSet from '../utils/sanitizeSrcSet';

type TitleProps = {
  title: string;
  description: string;
  image?: ImageFragment;
};

const ParallaxTriangle: React.FC<{ opacity: number; rotate: number }> = ({ opacity, rotate }) => (
  <Parallax
    css={{
      left: '5%',
      top: -140,
      [`@media (max-width: ${breakpoints.md}px)`]: {
        left: 'auto',
        right: 180,
        top: 100,
      },
    }}
    modifier={0.1}
  >
    <Triangle
      css={{
        color: colors.blue,
        opacity,
        width: 840,
        transform: `rotate(${rotate}deg)`,
        [`@media (max-width: ${breakpoints.md}px)`]: {
          width: 340,
        },
      }}
    />
  </Parallax>
);

const TitleText: React.FC<Omit<TitleProps, 'image'>> = ({ title, description }) => (
  <>
    <h1 css={{ marginBottom: '1rem' }}>{title}</h1>
    <p css={{ fontSize: `${dimensions.fontSize.large}rem` }}>{description}</p>
  </>
);

const TitleLargeImage: React.FC<TitleProps> = ({ title, description, image }) => {
  return (
    <div
      css={{
        marginBottom: 50,
        [`@media (max-width: ${breakpoints.md}px)`]: {
          marginBottom: 10,
        },
      }}
    >
      <Section level={2} css={{ position: 'relative' }}>
        <ParallaxTriangle opacity={0.05} rotate={128} />
        <div
          css={{
            position: 'relative',
            minHeight: 200,
            overflow: 'hidden',
            borderRadius: dimensions.borderRadius,
            backgroundColor: colors.blue,
            transform: 'rotate(0.5deg)',
          }}
        >
          <Parallax modifier={0.05} css={{ width: '100%', height: '100%' }}>
            {image && image.imageFile.childImageSharp && (
              <img
                alt={title}
                src={image.imageFile.childImageSharp.fluid.src}
                srcSet={sanitizeSrcSet(image.imageFile.childImageSharp.fluid.srcSet)}
                css={{
                  width: '110%',
                  height: '110%',
                  marginTop: '-4%',
                  objectFit: 'cover',
                  [`@media (max-width: ${breakpoints.md}px)`]: {
                    objectPosition: '100% 50%',
                  },
                }}
              />
            )}
          </Parallax>
          <ParallaxTriangle opacity={0.7} rotate={127} />
          <Section
            level={4}
            css={{
              position: 'relative',
              paddingTop: 130,
              paddingBottom: 110,
              paddingRight: '40%',
              color: colors.white,
              transform: 'rotate(-0.5deg)',
              [`@media (max-width: ${breakpoints.md}px)`]: {
                display: 'none',
              },
            }}
            pad
          >
            <TitleText title={title} description={description} />
          </Section>
        </div>
        <Parallax
          css={{
            right: '15%',
            bottom: 150,
            [`@media (max-width: ${breakpoints.md}px)`]: {
              display: 'none',
            },
          }}
          modifier={-0.1}
        >
          <Ring
            css={{
              color: colors.turquoise,
              width: 270,
              transform: 'rotate(93deg)',
              path: { strokeWidth: 1.1 },
              [`@media (max-width: ${breakpoints.md}px)`]: {
                width: 111,
                path: { strokeWidth: 4 },
              },
            }}
          />
        </Parallax>
      </Section>
      <Section
        level={4}
        css={{
          display: 'none',
          marginTop: 30,
          [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'block',
          },
        }}
        pad
      >
        <TitleText title={title} description={description} />
      </Section>
    </div>
  );
};

export default TitleLargeImage;
